import * as React from "react";
import { navigate } from "gatsby";
import api from "../util/api";

import { Button, TextField, Box, Alert, CircularProgress } from "@mui/material";
import Layout from "../components/Layout";
import { EmailOutlined } from "@mui/icons-material";
import Swal from "sweetalert2";
import Background from "/images/411440x810.jpeg";
import SaveIcon from "@mui/icons-material/Save";
import { validateRequire, validateRegex, validatePassword, validateMinCharacter, validateCompare } from "../util/validate";
import "./index.css";
import useTimer from "../components/useTimer";

export default function EditProfile(props) {
  const [validate_field, setValidateField] = React.useState({
    name: [],
    lastname: [],
    position: [],
    facebook_line: [],
    department: [],
    num_employee: [],
    objective: [],
    pass: [],
    confirmpass: [],
  });

  const [alert_err, setAlertErr] = React.useState("");
  const [open_modal, setOpenModal] = React.useState(false);
  const [onload, setOnLoad] = React.useState(false);
  const [user_data, setUserData] = React.useState({
    user: null,
    name: null,
    lastname: null,
    position: null,
    phone_number: null,
    email: null,
    facebook_line: null,
    department: null,
    num_employee: null,
    objective: null,

    line_connect: null,

    pass: null,
    confirmpass: null,
  });
  const [timer ,setTimer] = useTimer(null);

  React.useEffect(async () => {
    let res_profile = await api.getProfileDb();
    let profile_data = res_profile.profile.profile;
    setUserData({
      ...user_data,
      user: res_profile.profile.user ?? null,
      name: profile_data.name ?? "",
      lastname: profile_data.lastname ?? "",
      position: profile_data.position ?? "",
      phone_number: profile_data.phone_number ?? "",
      email: profile_data.email ?? "",
      facebook_line: profile_data.facebook_line ?? "",
      department: profile_data.department ?? "",
      num_employee: profile_data.num_employee ?? "",
      objective: profile_data.objective ?? "",

      line_connect: res_profile.profile.line_connect ?? false,

      pass: null,
      confirmpass: null,
    });
  }, []);

  const handleSubmit = (event) => {
    let check_validate = false;
    let editporfile_validate = onValidate(user_data);
    for (let key in editporfile_validate) {
      check_validate = editporfile_validate[key].filter((item) => item !== null).length > 0;
      if (check_validate) break;
    }
    if (!check_validate) {
      setOnLoad(true);
      onEditProfile(user_data);
    }
  };

  const onEditProfile = async (data) => {
    try {
      let update_data = {
        user: data.user,
        profile: {
          name: data.name,
          lastname: data.lastname,
          position: data.position,
          facebook_line: data.facebook_line,
          department: data.department,
          num_employee: data.num_employee,
          objective: data.objective,
        },
      };
      if (data.line_connect === false) update_data.line_connect = false;
      const res = await api.editprofile(update_data);
      setOnLoad(false);
      Swal.fire("แก้ไขข้อมูลส่วนตัวสำเร็จแล้ว", "", "success");
      if (data.line_connect && res.line_connect !== data.line_connect) {
        api.redirectLineConnect();
      } else {
        setTimeout(() => {
          setOpenModal(false);
          setUserData({ ...user_data, pass: null, confirmpass: null });
        }, 3000);
      }
    } catch (err) {
      setOnLoad(false);
      let text_alert = "";
      if (Array.isArray(err)) {
        err.forEach((ele, idx) => {
          idx === err.length - 1 ? (text_alert += ele) : (text_alert += ele + ", ");
        });
      } else {
        text_alert = err;
      }
      setAlertErr(text_alert);
    }
  };

  const onValidate = (data) => {
    let reg_text_en = /^[a-zA-Z0-9-_]+$/;
    let reg_text_number = /^[0-9]+$/;

    if (data.pass || data.confirmpass) {
      validate_field.pass = [];
      validate_field.pass.push(validateRequire(data.pass), validateMinCharacter(data.pass, 3), validateRegex(data.pass, reg_text_en, "a - Z, 0 - 9"));

      validate_field.confirmpass = [];
      validate_field.confirmpass.push(validateRequire(data.confirmpass), validatePassword(data.pass, data.confirmpass));
    }

    validate_field.name = [];
    validate_field.name.push(validateRequire(data.name));

    validate_field.lastname = [];
    validate_field.lastname.push(validateRequire(data.lastname));

    validate_field.department = [];
    validate_field.department.push(validateRequire(data.department));

    if (data.num_employee) {
      validate_field.num_employee = [];
      validate_field.num_employee.push(validateRegex(data.num_employee, reg_text_number, "ตัวเลขเท่านั้น"));
    } else {
      validate_field.num_employee = [];
    }

    setValidateField({ ...validate_field });

    return validate_field;
  };

  return (
    <Layout Role={["public-login", "partner", "eef", "representative"]}>
      <div className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${Background})` }}>
        <div className="pt-[100px] p-5 flex justify-center">
          <div className="w-full xl:w-8/12 rounded backdrop-brightness-50 bg-white shadow-lg">
            <Box
              // className='flex flex-col items-center'
              component="form"
              onSubmit={handleSubmit}
            >
              <div className="px-6 py-4">
                <div className="font-bold text-4xl mb-6">แก้ไขข้อมูลส่วนตัว</div>
                <div style={{ borderBottom: "1px solid black", width: "100%" }}></div>
                <div className="grid grid-cols-4 xl:grid-cols-3 gap-4 my-8">
                  <div className="col-span-4 text-xl my-auto xl:col-span-1">ข้อมูลทั่วไป</div>
                  <div className="col-span-4 md:col-span-2 md:col-start-1 xl:col-span-1 xl:col-start-2">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="ชื่อ"
                      name="name"
                      autoComplete="name"
                      onChange={(e) => setUserData({ ...user_data, name: e.target.value })}
                      value={user_data.name ? user_data.name : ""}
                      error={validate_field.name.filter((item) => item !== null).length > 0}
                      helperText={
                        validate_field.name.filter((item) => item !== null).length > 0 ? validate_field.name.filter((item) => item !== null)[0] : ""
                      }
                    />
                  </div>
                  <div className="col-span-4 md:col-span-2 md:col-start-3 xl:col-span-1 xl:col-start-3">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="lastname"
                      label="สกุล"
                      name="lastname"
                      autoComplete="lastname"
                      onChange={(e) => setUserData({ ...user_data, lastname: e.target.value })}
                      value={user_data.lastname ? user_data.lastname : ""}
                      error={validate_field.lastname.filter((item) => item !== null).length > 0}
                      helperText={
                        validate_field.lastname.filter((item) => item !== null).length > 0
                          ? validate_field.lastname.filter((item) => item !== null)[0]
                          : ""
                      }
                    />
                  </div>
                  <div className="col-span-4 md:col-span-2 md:col-start-1 xl:col-span-1 xl:col-start-2">
                    <TextField
                      margin="normal"
                      fullWidth
                      id="position"
                      label="ตำแหน่ง"
                      name="position"
                      autoComplete="position"
                      onChange={(e) => setUserData({ ...user_data, position: e.target.value })}
                      value={user_data.position ? user_data.position : ""}
                      error={validate_field.position.filter((item) => item !== null).length > 0}
                      helperText={
                        validate_field.position.filter((item) => item !== null).length > 0
                          ? validate_field.position.filter((item) => item !== null)[0]
                          : ""
                      }
                    />
                  </div>
                  <div className="col-span-4 md:col-span-2 md:col-start-3 xl:col-span-1 xl:col-start-3">
                    <TextField
                      className="bg-gray-100"
                      margin="normal"
                      required
                      fullWidth
                      id="phone_number"
                      label="เบอร์โทรศัพท์"
                      name="phone_number"
                      autoComplete="phone_number"
                      value={user_data.phone_number ? user_data.phone_number : ""}
                      disabled
                    />
                  </div>
                  <div className="col-span-4 md:col-span-2 md:col-start-1 xl:col-span-1 xl:col-start-2">
                    <TextField
                      className="bg-gray-100"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      value={user_data.email ? user_data.email : ""}
                      disabled
                    />
                  </div>
                  <div className="col-span-4 md:col-span-2 md:col-start-3 xl:col-span-1 xl:col-start-3">
                    <TextField
                      margin="normal"
                      fullWidth
                      id="facebook_line"
                      label="Facebook/Line"
                      name="facebook_line"
                      autoComplete="facebook_line"
                      onChange={(e) => setUserData({ ...user_data, facebook_line: e.target.value })}
                      value={user_data.facebook_line ? user_data.facebook_line : ""}
                      error={validate_field.facebook_line.filter((item) => item !== null).length > 0}
                      helperText={
                        validate_field.facebook_line.filter((item) => item !== null).length > 0
                          ? validate_field.facebook_line.filter((item) => item !== null)[0]
                          : ""
                      }
                    />
                  </div>
                  <div className="col-span-4 md:col-span-2 md:col-start-1 xl:col-span-1 xl:col-start-2">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="department"
                      label="หน่วยงาน"
                      name="department"
                      autoComplete="department"
                      onChange={(e) => setUserData({ ...user_data, department: e.target.value })}
                      value={user_data.department ? user_data.department : ""}
                      error={validate_field.department.filter((item) => item !== null).length > 0}
                      helperText={
                        validate_field.department.filter((item) => item !== null).length > 0
                          ? validate_field.department.filter((item) => item !== null)[0]
                          : ""
                      }
                    />
                  </div>
                  <div className="col-span-4 md:col-span-2 md:col-start-3 xl:col-span-1 xl:col-start-3">
                    <TextField
                      margin="normal"
                      fullWidth
                      id="num_employee"
                      label="จำนวนพนักงาน"
                      name="num_employee"
                      autoComplete="num_employee"
                      onChange={(e) => setUserData({ ...user_data, num_employee: e.target.value })}
                      value={user_data.num_employee ? user_data.num_employee : ""}
                      error={validate_field.num_employee.filter((item) => item !== null).length > 0}
                      helperText={
                        validate_field.num_employee.filter((item) => item !== null).length > 0
                          ? validate_field.num_employee.filter((item) => item !== null)[0]
                          : ""
                      }
                    />
                  </div>
                  <div className="col-span-4 md:col-span-4 md:col-start-1 xl:col-span-2 xl:col-start-2">
                    <TextField
                      id="outlined-multiline-static"
                      margin="normal"
                      fullWidth
                      multiline
                      label="วัตถุประสงค์ในการนำข้อมูลไปใช้"
                      name="objective"
                      autoComplete="objective"
                      onChange={(e) => setUserData({ ...user_data, objective: e.target.value })}
                      value={user_data.objective ? user_data.objective : ""}
                      error={validate_field.objective.filter((item) => item !== null).length > 0}
                      helperText={
                        validate_field.objective.filter((item) => item !== null).length > 0
                          ? validate_field.objective.filter((item) => item !== null)[0]
                          : ""
                      }
                    />
                  </div>
                </div>

                <div style={{ borderBottom: ".1px solid #cedada", width: "100%" }}></div>
                <div className="flex flex-row-reverse my-6 gap-4">
                  <Button
                    disabled={onload}
                    onClick={handleSubmit}
                    variant="contained"
                    startIcon={onload ? <CircularProgress size={16} color="inherit" /> : <SaveIcon />}
                  >
                    {onload ? "กำลังบันทึก..." : "บันทึกการเปลี่ยนแปลง"}
                  </Button>
                  <div className="flex gap-4">
                    {timer > 0 && (
                      <div className="flex justify-center items-center gap-4">
                        <h2 className="my-auto text-lg">ส่งอีเมลล์อีกครั้ง : </h2>
                        <p className="my-auto text-lg">{`00:${timer < 10 ? "0" : ""}${timer}`}</p>
                      </div>
                    )}
                    <Button
                      className="disabled:opacity-50"
                      disabled={onload || timer > 0}
                      onClick={async () => {
                        try {
                          await api.resetpass({ email: user_data.email });
                          setOnLoad(false);
                          setTimer(new Date().getTime() + 1000 * 60);
                          Swal.fire("", "ระบบได้ส่ง Link สำหรับเปลี่ยนรหัสผ่านใหม่ไปยัง Email : " + user_data.email + " นี้แล้ว", "success");
                        } catch (err) {
                          setOnLoad(false);
                          Swal.fire("", err + "", "error");
                        }
                      }}
                      style={{ backgroundColor: "#1e4a7a", color: "white" }}
                      variant="contained"
                      startIcon={onload ? <CircularProgress size={16} color="inherit" /> : <EmailOutlined />}
                    >
                      {onload ? "กำลังส่งเมล์..." : "เปลี่ยนรหัสผ่าน"}
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Layout>
  );
}
